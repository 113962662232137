var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%", "background-color": "#ffffff" } },
    [
      _c("head-layout", {
        attrs: {
          "head-title": "检查基本信息",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave(false)
          },
          "head-saveBack": function ($event) {
            return _vm.headSave(true)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _c(
          "div",
          { staticClass: "formMain", staticStyle: { padding: "0 12px" } },
          [
            _c(
              "el-form",
              {
                ref: "dataForm",
                attrs: {
                  model: _vm.dataForm,
                  "label-width": "150px",
                  disabled: _vm.formType == "view",
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "检查编号", prop: "prjCode" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入项目编码" },
                              model: {
                                value: _vm.dataForm.prjCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "prjCode", $$v)
                                },
                                expression: "dataForm.prjCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 3 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "自动生成", prop: "prjCodeAuto" } },
                          [
                            _c("el-switch", {
                              model: {
                                value: _vm.prjCodeAuto,
                                callback: function ($$v) {
                                  _vm.prjCodeAuto = $$v
                                },
                                expression: "prjCodeAuto",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "检查名称", prop: "prjName" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入项目名称" },
                              model: {
                                value: _vm.dataForm.prjName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "prjName", $$v)
                                },
                                expression: "dataForm.prjName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "检查类型", prop: "isDomestic" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择境内外" },
                                model: {
                                  value: _vm.dataForm.isDomestic,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "isDomestic", $$v)
                                  },
                                  expression: "dataForm.isDomestic",
                                },
                              },
                              _vm._l(_vm.prjDomesticList, function (item) {
                                return _c("el-option", {
                                  key: item.dictKey,
                                  attrs: {
                                    label: item.dictValue,
                                    value: item.dictKey,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "发起人", prop: "contractName" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请填写承建单位" },
                              model: {
                                value: _vm.dataForm.contractName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "contractName", $$v)
                                },
                                expression: "dataForm.contractName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 3 } },
                      [_c("el-form-item", { attrs: { label: "" } })],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "所属项目", prop: "areName" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请填写项目区域" },
                              model: {
                                value: _vm.dataForm.areName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "areName", $$v)
                                },
                                expression: "dataForm.areName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "检查日期", prop: "prjDate" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请填写立项日期" },
                              model: {
                                value: _vm.dataForm.prjDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "prjDate", $$v)
                                },
                                expression: "dataForm.prjDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注", prop: "prjAs" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请填写项目简称",
                                maxlength: "50",
                              },
                              model: {
                                value: _vm.dataForm.prjAs,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "prjAs", $$v)
                                },
                                expression: "dataForm.prjAs",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "shuttleBackBox" },
              [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "检查项", name: "corePersonnel" } },
                      [
                        _c(
                          "el-container",
                          [
                            _c("CommonTree", {
                              attrs: {
                                treeTitle: "检查项分类",
                                isShowdig: "",
                                searchTitle: _vm.searchTitle,
                                treeData: _vm.corePersonnelTreeData,
                                defaultProps: _vm.defaultProps,
                                showCheckbox: false,
                                treeExpand: false,
                              },
                              on: {
                                getTreeAdd: _vm.getTreeAdd,
                                getTreeDelete: _vm.getTreeDelete,
                                getTreeEdit: _vm.getTreeEdit,
                                getNodeClick: _vm.corePersonnelTreeNodeClick,
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "table-box" },
                              [
                                _vm.formType != "view"
                                  ? _c("head-layout", {
                                      attrs: {
                                        "head-btn-options":
                                          _vm.corePersonnelHeadBtnOptions,
                                        "head-title": "检查项",
                                      },
                                      on: {
                                        handleImport: _vm.handleImport,
                                        "head-export": _vm.headExport,
                                      },
                                    })
                                  : _vm._e(),
                                _c("grid-layout", {
                                  ref: "gridLayOut",
                                  attrs: {
                                    "table-options":
                                      _vm.corePersonnelTableOption,
                                    "table-data": _vm.corePersonnelTableData,
                                    "table-loading": _vm.tableLoading,
                                  },
                                  on: {
                                    "gird-handle-select-click":
                                      _vm.selectionChange,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      _vm.formType != "view"
                                        ? {
                                            key: "customBtn",
                                            fn: function ({ row }) {
                                              return [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      margin: "0 3px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.rowdel(row)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "删除\n                    "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "检查人员",
                          name: "thirdPartyOrganization",
                        },
                      },
                      [
                        _vm.formType != "view"
                          ? _c("head-layout", {
                              attrs: {
                                "head-title": "检查人员",
                                "head-btn-options": _vm.userBtnOptions,
                              },
                              on: {
                                "head-add": _vm.headAdd,
                                "head-remove": _vm.headRemove,
                              },
                            })
                          : _vm._e(),
                        _c("grid-layout", {
                          ref: "gridLayOut",
                          attrs: {
                            "table-options":
                              _vm.thirdPartyOrganizationTableOption,
                            "table-data": _vm.thirdPartyOrganizationTableData,
                            "table-loading": _vm.tableLoading,
                          },
                          on: {
                            "gird-handle-select-click": _vm.selectionChange,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "相关资料 ",
                          name: "thirdPartyPersonnel",
                        },
                      },
                      [
                        _vm.formType != "view"
                          ? _c(
                              "head-layout",
                              {
                                attrs: {
                                  "head-title": "资料清单",
                                  "head-btn-options": _vm.fileBtnOptions,
                                },
                                on: { headImport: _vm.headImport },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { slot: "right", size: "mini" },
                                    slot: "right",
                                  },
                                  [_vm._v("引用")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("el-upload", {
                          staticClass: "upload-demo",
                          attrs: { disabled: "", "file-list": _vm.fileList },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "问题清单", name: "taskList" } },
                      [
                        _vm.formType != "view"
                          ? _c("head-layout", { attrs: { "head-title": "" } })
                          : _vm._e(),
                        _c("grid-layout", {
                          ref: "gridLayOut",
                          attrs: {
                            "table-options": _vm.corePersonnelTableOption,
                            "table-data": _vm.thirdPartyPersonnelTableData,
                            "table-loading": _vm.tableLoading,
                          },
                          on: {
                            "gird-handle-select-click": _vm.selectionChange,
                          },
                          scopedSlots: _vm._u(
                            [
                              _vm.formType != "view"
                                ? {
                                    key: "customBtn",
                                    fn: function ({ row }) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { margin: "0 3px" },
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.rowdel(row)
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ]
                                    },
                                  }
                                : null,
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _vm.formType == "view"
                      ? _c(
                          "el-tab-pane",
                          { attrs: { label: "整改通知书", name: "Rectify" } },
                          [
                            _c(
                              "el-container",
                              [
                                _c("el-upload", {
                                  staticClass: "upload-demo",
                                  attrs: {
                                    disabled: "",
                                    "file-list": _vm.fileList,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.formType == "view"
                      ? _c(
                          "el-tab-pane",
                          {
                            attrs: { label: "处罚通知书", name: "punishment" },
                          },
                          [
                            _c(
                              "el-container",
                              [
                                _c("el-upload", {
                                  staticClass: "upload-demo",
                                  attrs: {
                                    disabled: "",
                                    "file-list": _vm.fileList,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("el-upload", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hideUpload,
            expression: "hideUpload",
          },
        ],
        ref: "uploadRef",
        staticClass: "upload-demo",
        attrs: {
          action:
            "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km",
          "show-file-list": false,
          "on-success": _vm.handleSuccess,
          headers: _vm.headers,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.deptShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "检查分类导入",
            "append-to-body": "",
            visible: _vm.excelBox,
            width: "555px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.excelBox = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "import" },
            [
              _c(
                "avue-form",
                {
                  attrs: {
                    option: _vm.excelOption,
                    "upload-after": _vm.uploadAfter,
                  },
                  model: {
                    value: _vm.excelOption.excelForm,
                    callback: function ($$v) {
                      _vm.$set(_vm.excelOption, "excelForm", $$v)
                    },
                    expression: "excelOption.excelForm",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "excelTemplate" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleTemplate },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("cip.plat.sys.user.field.download"))
                          ),
                          _c("i", {
                            staticClass: "el-icon-download el-icon--right",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }